<!--
 * @Author: your name
 * @Date: 2021-12-07 19:08:57
 * @LastEditTime: 2022-01-28 16:32:34
 * @LastEditors: Please set LastEditors
 * @Description: 登录类型
 * @FilePath: \saier-christmas\src\components\aboutLogin\loginType.vue
-->
<template >
	<SelfModal :show="show" @close="close">
	
		<div class="tit">{{ title }}</div>
		<!-- 选择登录方式 -->
		<div class="type_box" v-show="!login_type">
			<div class="phone" @click="choosetype('phonecode')">
				<img src="https://wcdn.tomatogames.com/web/guonei/sezg/christmas/phone.png" alt="">
				<div class="txt">手机登录</div> 
			</div>
			<div class="account" @click="choosetype('account')">
				<img src="https://wcdn.tomatogames.com/web/guonei/sezg/christmas/account.png" alt="">
				<div class="txt">账号登录</div> 
			</div>
		</div>
		<!-- 登录 -->
		<div class="login_box" v-show="login_type">

			<div class="input_item" v-show="['phonecode','phonepw'].includes(login_type)">
				<input type="text" placeholder="请输入手机号码" v-model="params.phone">
			</div>

			<div class="input_item" v-show="login_type == 'phonecode'">
				<input type="text" placeholder="请输入验证码" v-model="params.code">
				<div class="codebtn fr" v-show="login_type == 'phonecode'" @click="sendCode"> {{ codetxt }}</div>
			</div>

			<div class="input_item" v-show="login_type == 'account'">
				<input type="text" placeholder="请输入账号" v-model="params.account">
			</div>

			<div class="input_item" v-show="login_type != 'phonecode'">
				<input type="password" placeholder="请输入密码（6-20位）" v-model="params.password">
			</div>

			<div class="toogle">
				<div class="backto" @click="backTo">返回</div>
				<div class="change" v-show="['phonecode','phonepw'].includes(login_type)" @click="choosetype(login_type == 'phonecode' ? 'phonepw' : 'phonecode')">
					{{ login_type == 'phonecode' ? '密码登录' : '验证码登录' }}
				</div>
			</div>

			<div class="login_btn" v-show="login_type" @click="tologin">登录</div>
		</div>
	</SelfModal>
</template>
<script>
import { sendCode,login} from "@/utils/api.js";
import SelfModal from "./Modal.vue";
export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  components: { SelfModal: SelfModal },
  name: "",
  data() {
    return {
      login_type: "",
      // 验证码登录或密码登录
      pass_type: "",
      // 发送验证码的定时器
      codetxt: "获取验证码",
      secondtime: 59,
      timer: null,
			params:{
				phone:'',
				// account:'cattang',
				account:'',
				code:'',
				// password:'cat309211CAT',
				password:'',
			},
			fcode:'',
    };
  },
	watch:{
		show(val){
			this.clearTimer()
		}
	},
  computed: {
    title() {
      let txt = ["phonecode", "phonepw"].includes(this.login_type)
        ? "手机登录"
        : this.login_type == "account"
        ? "账号登录"
        : "";
      return txt || "请选择登录方式";
    },
  },
  methods: {
    close() {
      this.$store.commit("SETVAL", { loginStep: "" });
    },
    choosetype(type) {
      this.login_type = type;
			// this.params={
			// 	phone:'',
			// 	account:'',
			// 	code:'',
			// 	password:'',
			// }
    },
    backTo() {
      this.login_type = "";
    },
    // 发送验证码
    sendCode() {
      if (!this.params.phone) {
        this.$toast("请先填写手机号");
        return;
      }
			var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; 
			if (!reg_tel.test(this.params.phone)) {
				this.$toast("请填写正确的手机号");
        return;
			}
      if (this.timer) return;
      let time = parseInt(new Date().getTime());
      let params = {
        time: time,
        to_user: this.params.phone,
        project_id: 40,
        type: 1,
        flag: 0,
        access: [time, 40,this.params.phone, 1]
      };
      sendCode(params)
        .then((data) => {
          this.codetxt = this.secondtime + "s后获取";
          this.$toast("短信发送成功，请注意查收");
          this.timer = setInterval(() => {
            this.secondtime--;
            if (this.secondtime <= 0) {
              clearInterval(this.timer);
              this.timer = null;
              this.codetxt = "获取验证码";
              this.secondtime = 60;
              return;
            }
            this.codetxt = this.secondtime + "s后获取";
          }, 1000);
        })
        .catch((err) => {
          // clearInterval(this.timer);
          // this.timer = null;
          this.$toast(err.msg || "短信发送失败");
        });
    },
    // 清除定时器
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
		tologin(){
			let time = parseInt(new Date().getTime());
			let account = this.login_type == "account" ? this.params.account :this.params.phone
			let type = this.login_type ==  'phonecode' ? 2:1
			let params  = {time,account,type}
			params= Object.assign(params,{access: [time,account,type],project_id:40})
			this.fcode && (params.fcode = this.fcode)

			this.login_type ==  'phonecode' ? (params.code = this.params.code ) :(params.password = this.params.password)
			login(params).then(data=>{
				localStorage.setItem('token',data.token)
				localStorage.setItem('uid',data.uid)
				localStorage.setItem('code',data.code)
				// this.$store.dispatch('getUserInfo',data.token)
        this.$store.commit("SETVAL", { bindrole: true, loginStep: ""  });
			}).catch(err=>{
				this.$toast(err.msg || '登录失败，请重试')
			})
		}
  },
  mounted() {
		let query = this.$route.query 
		if(query && query.fcode ) {
			localStorage.setItem('fcode',query.fcode)
		}
		this.fcode = (query && query.fcode) ? query.fcode :localStorage.getItem('fcode') || ''
  },
	
};
</script>
<style scoped lang='stylus'></style>